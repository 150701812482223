import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import s from './UserMenu.scss';
import Label from '../Label';
import Link from '../Link';
import List from '../List';
import Clickable from '../Clickable';
import { ICONS, MY_PATH, LOGOUT_PATH } from '../../constants';
import { userMessages as messages } from '../../defineMessages';

const UserMenu = ({ user, ...props }) => (
  <List container alignItems="center" className={s.root}>
    <List item component="li">
      <Clickable
        tag="span"
        basic
        link={<Link to={MY_PATH} />}
        icon={{ name: ICONS.USER }}
      />
    </List>
    <List item className={s.item} component="li">
      <Clickable naked link={<Link to={MY_PATH} />} {...props}>
        <Label bold className={s.label}>
          {user.name}
        </Label>
        {user.company && <Label className={s.label}>{user.company}</Label>}
      </Clickable>
      <Link unprefixed to={LOGOUT_PATH} className={s.logout}>
        <FormattedMessage {...messages.mobileLogout} />
      </Link>
    </List>
  </List>
);

UserMenu.propTypes = {
  user: PropTypes.shape().isRequired,
};

export default withStyles(s)(UserMenu);
