/* eslint-disable import/prefer-default-export */
import { CLOSE_SERVICE_MESSAGE } from '../constants';

export function closeMessage() {
  return async (dispatch, getState) => {
    const {
      intl: { countryCode },
    } = getState();
    dispatch({
      type: CLOSE_SERVICE_MESSAGE,
      payload: {
        showMessage: false,
        countryCode,
      },
    });
  };
}
