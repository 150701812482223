import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { connectStateResults } from 'react-instantsearch-dom';
import { filterSearchResults } from '../../helpers';

const SearchStateResults = props => {
  const {
    searchResults,
    children,
    filters: { recipient, category, occasion, brand },
  } = props;

  if (!searchResults || !(searchResults && searchResults.query !== undefined)) {
    return null;
  }

  const { index, hits } = searchResults;
  let hasResults = false;
  switch (index) {
    case 'brand': {
      const brandIdsInState = [...Object.keys(brand)];
      hasResults = filterSearchResults(hits, brandIdsInState).length > 0;
      break;
    }
    case 'category': {
      const categoryIdsInState = [
        ...Object.keys(recipient),
        ...Object.keys(category),
        ...Object.keys(occasion),
      ];
      hasResults = filterSearchResults(hits, categoryIdsInState).length > 0;
      break;
    }
    default: {
      hasResults = searchResults.nbHits > 0;
    }
  }
  return children({ hasResults });
};

SearchStateResults.propTypes = {
  searchResults: PropTypes.shape(),
  children: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
};

SearchStateResults.defaultProps = {
  searchResults: undefined,
};
const mapState = state => ({
  filters: state.shop.filters,
});

export default compose(connect(mapState))(
  connectStateResults(SearchStateResults),
);
