import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Configure, InstantSearch, Index } from 'react-instantsearch-dom';
import withStyles from 'isomorphic-style-loader/withStyles';
import resetCss from 'instantsearch.css/themes/reset.css';
import s from './Search.scss';
import Grid from '../Grid/Grid';
import Hits from '../Hits';
import Label from '../Label/Label';
import List from '../List/List';
import Modal from '../Modal';
import SearchBox from '../SearchBox';
import SearchStateResults from '../SearchStateResults';
import { ICONS, SHOP_PATH } from '../../constants';
import withResponsiveWrapper from '../../decorators/withResponsiveWrapper';
import { searchMessages as messages } from '../../defineMessages';
import SearchListItem from '../SearchListItem/SearchListItem';
import ApplicationContext from '../ApplicationContext';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.onItemClick = this.onItemClick.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchBlur = this.onSearchBlur.bind(this);
    this.onSearchEsc = this.onSearchEsc.bind(this);

    this.state = {
      modalOpen: false,
      desktopOverlay: false,
    };
  }

  onSearchClick() {
    this.setState({
      ...this.state,
      desktopOverlay: true,
    });
  }

  onSearchEsc(e) {
    if (e.keyCode === 27) {
      this.setState({
        ...this.state,
        desktopOverlay: false,
      });
    }
  }

  onSearchBlur() {
    setTimeout(() => {
      this.setState({
        ...this.state,
        desktopOverlay: false,
      });
    }, 200);
  }

  onItemClick() {
    // Delay modal close on item click
    setTimeout(() => {
      this.setState({
        ...this.state,
        modalOpen: false,
        desktopOverlay: false,
      });
    }, 200);
  }

  static contextType = ApplicationContext;

  render() {
    const {
      isDesktop,
      popularProducts,
      salesChannelId,
      countryCode,
      intl,
    } = this.props;

    const { indexPrefix, ...searchClient } = this.context.algoliaClient;

    const indices = [
      {
        indexName: 'category',
        label: intl.formatMessage(messages.categories),
        noResultsMsg: intl.formatMessage(messages.categoryNoResults),
        filters: `availableInSalesChannels:${salesChannelId}`,
      },
      {
        indexName: 'brand',
        label: intl.formatMessage(messages.brands),
        noResultsMsg: intl.formatMessage({ ...messages.brandNoResults }),
        filters: '',
      },
      {
        indexName: 'product',
        label: intl.formatMessage(messages.products),
        noResultsMsg: intl.formatMessage(messages.productNoResults),
        filters: `availableInSalesChannels:${salesChannelId} AND redeemableInCountries: ${countryCode}`,
      },
    ];

    const noResultItems = i => (
      <>
        <List component="li" item grow className={s.item}>
          <Grid
            container
            alignContent="flex-start"
            alignItems="center"
            spacing={16}
          >
            <Grid item className={s.noResultsMsg}>
              <Label>{i.noResultsMsg}</Label>
            </Grid>
          </Grid>
        </List>
        {i.indexName === 'product' &&
          popularProducts.slice(0, 3).map(p => (
            <SearchListItem
              title={p.productTitle}
              description={p.productShortDescription}
              slug={`${SHOP_PATH}/${slugify(p.productTitle || '-', {
                lower: true,
              })}/${p.id}`}
              image={
                p.productLogo && p.productLogo[0]
                  ? p.productLogo[0].thumbnail
                  : ''
              }
              price={p.price}
              type={i.indexName}
              onItemClick={this.onItemClick}
            />
          ))}
      </>
    );

    return (
      <>
        {isDesktop && (
          <div
            className={this.state.desktopOverlay ? s.desktopOverlay : ''}
            role="search"
          />
        )}
        <div className={s.root}>
          <InstantSearch
            searchClient={searchClient}
            indexName={indexPrefix ? `${indexPrefix}product` : 'product'}
          >
            {isDesktop ? (
              <>
                <SearchBox
                  onSearchClick={this.onSearchClick}
                  onSearchBlur={this.onSearchBlur}
                  onSearchEsc={this.onSearchEsc}
                />
                <Grid container className={s.hits}>
                  <Grid item md={8} className={s.left}>
                    {indices.map(i => (
                      <React.Fragment key={i.indexName}>
                        {i.indexName !== 'product' && (
                          <Index
                            indexName={
                              indexPrefix
                                ? indexPrefix + i.indexName
                                : i.indexName
                            }
                            key={i.indexName}
                          >
                            <Configure
                              filters={i.filters}
                              hitsPerPage={i.hitsPerPage}
                            />
                            <SearchStateResults>
                              {({ hasResults }) =>
                                hasResults ? (
                                  <Hits
                                    index={i}
                                    onItemClick={this.onItemClick}
                                  />
                                ) : (
                                  <>
                                    <div className={s.indexLabel}>
                                      <Label bold uppercase>
                                        {i.label}
                                      </Label>
                                    </div>
                                    <List
                                      container
                                      direction="column"
                                      className={s.noResults}
                                    >
                                      {noResultItems(i)}
                                    </List>
                                  </>
                                )
                              }
                            </SearchStateResults>
                          </Index>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                  <Grid item md={16} className={s.right}>
                    {indices.map(i => (
                      <React.Fragment key={i.indexName}>
                        {i.indexName === 'product' && (
                          <Index
                            indexName={
                              indexPrefix
                                ? indexPrefix + i.indexName
                                : i.indexName
                            }
                            key={i.indexName}
                          >
                            <Configure
                              filters={i.filters}
                              hitsPerPage={i.hitsPerPage}
                            />
                            <SearchStateResults>
                              {({ hasResults }) =>
                                hasResults ? (
                                  <Hits
                                    index={i}
                                    onItemClick={this.onItemClick}
                                  />
                                ) : (
                                  <>
                                    <div className={s.indexLabel}>
                                      <Label bold uppercase>
                                        {i.label}
                                      </Label>
                                    </div>
                                    <List
                                      container
                                      direction="column"
                                      className={s.noResults}
                                    >
                                      {noResultItems(i)}
                                    </List>
                                  </>
                                )
                              }
                            </SearchStateResults>
                          </Index>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Modal
                isVisible={this.state.modalOpen}
                fullScreen
                maxWidth
                animation="slideDown"
                headerInput={<SearchBox autoFocus />}
                handleClick={() => {
                  this.setState({
                    ...this.state,
                    modalOpen: !this.state.modalOpen,
                  });
                }}
                triggerProps={{
                  icon: { name: ICONS.SEARCH, size: 16 },
                  basic: true,
                }}
              >
                {indices.map(i => (
                  <Index
                    indexName={
                      indexPrefix ? indexPrefix + i.indexName : i.indexName
                    }
                    key={i.indexName}
                  >
                    <Configure
                      filters={i.filters}
                      hitsPerPage={i.hitsPerPage}
                    />
                    <SearchStateResults>
                      {({ hasResults }) =>
                        hasResults ? (
                          <Hits index={i} onItemClick={this.onItemClick} />
                        ) : (
                          <List
                            container
                            direction="column"
                            className={s.noResults}
                          >
                            <List
                              component="li"
                              item
                              grow
                              className={s.indexLabel}
                            >
                              <Label bold uppercase>
                                {i.label}
                              </Label>
                            </List>
                            {noResultItems(i)}
                          </List>
                        )
                      }
                    </SearchStateResults>
                  </Index>
                ))}
              </Modal>
            )}
          </InstantSearch>
        </div>
      </>
    );
  }
}

Search.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  popularProducts: PropTypes.arrayOf(PropTypes.shape()),
  salesChannelId: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  intl: PropTypes.shape().isRequired,
};

Search.defaultProps = {
  popularProducts: [],
};

const mapState = state => ({
  popularProducts: state.home.popularProducts,
  salesChannelId: state.config.salesChannelId,
  countryCode: state.intl.countryCode,
});

export default compose(
  withStyles(resetCss, s),
  withResponsiveWrapper(),
  injectIntl,
  connect(mapState),
)(Search);
