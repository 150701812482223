import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SearchListItem.scss';
import Grid from '../Grid/Grid';
import Label from '../Label/Label';
import Link from '../Link/Link';
import List from '../List/List';
import Price from '../Price';
import { searchMessages as messages } from '../../defineMessages';

class SearchListItem extends React.Component {
  constructor(props) {
    super(props);
    this.onItemClick = this.onItemClick.bind(this);
  }

  onItemClick(ev) {
    if (this.props.onItemClick) {
      this.props.onItemClick(ev);
    }
  }

  render() {
    const {
      title,
      description,
      image,
      slug,
      price,
      currency,
      type,
      intl,
    } = this.props;
    return (
      <List
        component="li"
        item
        grow
        className={type === 'product' ? s.productItem : s.item}
      >
        <Link to={slug} onClick={this.onItemClick}>
          <Grid
            container
            alignContent="stretch"
            justify="space-between"
            wrap="nowrap"
            spacing={16}
          >
            {image && (
              <Grid className={s.image}>
                <img
                  src={
                    image || 'https://via.placeholder.com/60?text=Placeholder'
                  }
                  alt={intl.formatMessage(messages.altProductLogo, {
                    title,
                  })}
                />
              </Grid>
            )}
            <Grid item className={s.title}>
              <Label bold={type === 'product'} tag="p">
                {title}
              </Label>
              {description && <Label>{description}</Label>}
            </Grid>
            {price && (
              <Grid item>
                <Label bold className={s.price}>
                  <FormattedMessage {...messages.fromPrice} />
                  &nbsp;
                  <Price value={price} currency={currency} />
                </Label>
              </Grid>
            )}
          </Grid>
        </Link>
      </List>
    );
  }
}

SearchListItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string,
  slug: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired,
  intl: PropTypes.shape().isRequired,
};

SearchListItem.defaultProps = {
  description: undefined,
  image: undefined,
  price: undefined,
  currency: undefined,
};

export default compose(withStyles(s), injectIntl)(SearchListItem);
