import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import ReactModal from 'react-modal';
import withStyles from 'isomorphic-style-loader/withStyles';
// eslint-disable-next-line css-modules/no-unused-class
import s from './RedeemOverview.scss';
import { ICONS, ACCOUNT_TYPE } from '../../constants';
import Clickable from '../Clickable';
import Label from '../Label';
import Price from '../Price';
import Grid from '../Grid';
import Link from '../Link';
import RegisteredCodes from './components/RegisteredCodes';
import { redeemMessages as messages } from '../../defineMessages';
import { createMarkup } from '../../helpers';
import {
  getHideRedeemOverviewNotice,
  setHideRedeemOverviewNotice,
} from '../../helpers/userStorage';

const RedeemOverview = ({
  intl,
  mode,
  vouchers,
  redeems,
  products,
  paymentCurrency,
  totalVoucherAmount,
  totalPurchaseAmountWithTax,
  productPage,
}) => {
  const [hideRedeemOverview, SetHideRedeemOverview] = useState(
    getHideRedeemOverviewNotice(),
  );

  const [hideRedeemOverviewNotice, SetHideRedeemOverviewNotice] = useState(
    getHideRedeemOverviewNotice(),
  );

  useEffect(() => {
    setHideRedeemOverviewNotice(hideRedeemOverviewNotice);
  }, [hideRedeemOverviewNotice]);

  /**
   *  findProduct finds the name of the item in the basket by looking through all existing products.
   * @param {Number} itemId - id of product in basket
   */
  const findProduct = itemId => {
    const product = products.find(({ id }) => itemId === id);
    return (product && product.productTitle) || '';
  };

  if (mode === ACCOUNT_TYPE.REDEEM) {
    return (
      <div className={cx(s.root, productPage && s.root__productPage)}>
        <Clickable
          className={s.header}
          onClick={() => SetHideRedeemOverview(!hideRedeemOverview)}
        >
          <Label
            icon={{ name: ICONS.GIFTCARD, size: 24 }}
            className={s.header__title}
          >
            {intl.formatMessage({ ...messages.redeemOverviewTitle })}
          </Label>
          <Label
            icon={{ name: ICONS.GIFTCARD, size: 24 }}
            className={s.header__balance}
          >
            {intl.formatMessage({ ...messages.redeemBalance })}
            <span className={s.fee__price}>
              <Price
                value={totalVoucherAmount - totalPurchaseAmountWithTax}
                currency={paymentCurrency}
              />
            </span>
          </Label>
        </Clickable>

        <ReactModal
          isOpen={!hideRedeemOverview}
          contentLabel="redeemOverview"
          onRequestClose={() => SetHideRedeemOverview(!hideRedeemOverview)}
          className={{
            base: cx(s.root, productPage && s.root__productPage),
            afterOpen: s.afterOpen,
            beforeClose: s.beforeClose,
          }}
          overlayClassName={s.overlay}
          bodyOpenClassName={s.open}
          htmlOpenClassName={s.open}
          closeTimeoutMS={300}
        >
          <Clickable
            className={s.header}
            onClick={() => SetHideRedeemOverview(!hideRedeemOverview)}
          >
            <Label
              icon={{ name: ICONS.GIFTCARD, size: 24 }}
              className={s.header__balance}
            >
              {intl.formatMessage({ ...messages.redeemOverviewTitle })}
            </Label>
          </Clickable>

          {!hideRedeemOverviewNotice ? (
            <div className={s.banner}>
              <div>
                <Label tag="h2" bold className={s.banner__header}>
                  {intl.formatMessage({ ...messages.redeemCongratulation })}
                </Label>
                <p
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={createMarkup(
                    intl.formatMessage({ ...messages.redeemSubtitle }),
                  )}
                />
              </div>

              <Grid container justify="space-around" spacing={16} wrap="wrap">
                <Grid item xs={12} sm="auto">
                  <Clickable
                    basic
                    fluid
                    link={<Link to="/how-to-redeem-your-gift-card" />}
                  >
                    {intl.formatMessage({
                      ...messages.redeemOverviewTellMeMore,
                    })}
                  </Clickable>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <Clickable
                    primary
                    fluid
                    onClick={() =>
                      SetHideRedeemOverviewNotice(!hideRedeemOverviewNotice)
                    }
                  >
                    {intl.formatMessage({
                      ...messages.redeemOverviewIUnderstand,
                    })}
                  </Clickable>
                </Grid>
              </Grid>
            </div>
          ) : (
            <>
              <div className={cx(s.item, s.item__header)}>
                <div className={s.fee}>
                  <Label bold className={s.fee__label}>
                    {intl.formatMessage({ ...messages.redeemBalance })}
                  </Label>
                  <Label>
                    <Price
                      value={totalVoucherAmount - totalPurchaseAmountWithTax}
                      currency={paymentCurrency}
                    />
                  </Label>
                </div>
              </div>
              <ul className={s.list}>
                {vouchers &&
                  vouchers.map(item => (
                    <li className={s.item} key={item.id}>
                      <div className={s.fee}>
                        <Label className={s.fee__label}>
                          {intl.formatMessage({
                            ...messages.redeemOverviewCodeAdded,
                          })}
                        </Label>
                        <Label>
                          <Price
                            prefix="+"
                            value={item.paymentAmount}
                            currency={paymentCurrency}
                          />
                        </Label>
                        <div className={cx(s.priceType, s.priceType__add)} />
                      </div>
                    </li>
                  ))}
                {redeems &&
                  products &&
                  [...redeems].reverse().map(item => (
                    <li className={s.item} key={item.lineId}>
                      <div className={s.fee}>
                        <Label className={s.quantity}>
                          {`${item.quantity}x`}
                        </Label>
                        <Label className={s.fee__label}>
                          {findProduct(item.productId)}
                        </Label>
                        <Label>
                          <Price
                            prefix="-"
                            value={item.totalPaymentAmountWithDiscountNoTax}
                            currency={paymentCurrency}
                          />
                        </Label>
                        <div
                          className={cx(s.priceType, s.priceType__subtract)}
                        />
                      </div>
                    </li>
                  ))}
              </ul>
              <RegisteredCodes />
            </>
          )}
        </ReactModal>
      </div>
    );
  }
  return false;
};

RedeemOverview.propTypes = {
  intl: PropTypes.shape().isRequired,
  mode: PropTypes.string.isRequired,
  vouchers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  redeems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paymentCurrency: PropTypes.string.isRequired,
  totalVoucherAmount: PropTypes.number.isRequired,
  totalPurchaseAmountWithTax: PropTypes.number.isRequired,
  productPage: PropTypes.bool,
};

RedeemOverview.defaultProps = {
  productPage: false,
};

const mapState = state => ({
  vouchers: state.basket.vouchers,
  mode: state.user.mode,
  paymentCurrency: state.basket.paymentCurrency,
  totalVoucherAmount: state.basket.totalVoucherAmount,
  totalPurchaseAmountWithTax: state.basket.totalPurchaseAmountWithTax,
  redeems: state.basket.data,
  products: state.shop.data,
});

export default compose(
  injectIntl,
  withStyles(s),
  connect(mapState),
)(RedeemOverview);
