import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import Label from '../Label';
import Link from '../Link';
import Clickable from '../Clickable';
import Icon from '../Icon';
import { ICONS, openInNewTabProp } from '../../constants';
import { closeMessage } from '../../actions/message';
import { IS_EXTERNAL } from '../../constants/config';
import s from './ServiceMessage.scss';

// todo:
// - add transition on close
// - add transition on hover if long message??

const ServiceMessage = ({
  message: { title, body, link, linkLabel, closable, showMessage },
  dispatch,
}) => {
  const [serviceMessage, setServiceMessage] = useState(showMessage);

  const onClose = () => {
    if (serviceMessage && closable) {
      setServiceMessage(false);
      dispatch(closeMessage());
    }
  };

  const isExternal = IS_EXTERNAL.test(link);

  return (
    <div className={s.root}>
      <div className={s.container}>
        <div className={cx(s.content, closable && s.content__closable)}>
          <Label bold tag="h6">
            {title}
          </Label>
          <p className={s.body}>{body}</p>
          {link && linkLabel && (
            <>
              {isExternal ? (
                <a
                  href={link}
                  target={openInNewTabProp}
                  rel="noopener noreferrer nofollow"
                >
                  {linkLabel}
                </a>
              ) : (
                <Link to={link}>{linkLabel}</Link>
              )}
            </>
          )}
        </div>
        {closable && (
          <Clickable className={s.close} onClick={() => onClose()}>
            <Icon name={ICONS.CLOSE} fill="#fff" size={12} />
          </Clickable>
        )}
      </div>
    </div>
  );
};

ServiceMessage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  message: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    link: PropTypes.string,
    linkLabel: PropTypes.string,
    closable: PropTypes.bool,
    showMessage: PropTypes.bool.isRequired,
  }),
};

ServiceMessage.defaultProps = {
  message: {
    title: undefined,
    body: undefined,
    link: undefined,
    linkLabel: undefined,
    closable: false,
    showMessage: false,
  },
};

const mapState = state => ({
  message: state.message[state.intl.countryCode],
});

export default compose(withStyles(s), connect(mapState))(ServiceMessage);
