import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { connectSearchBox } from 'react-instantsearch-dom';
import debounce from 'lodash/debounce';
import TextInput from '../TextInput';
import { ICONS } from '../../constants';
import { searchMessages as messages } from '../../defineMessages';

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    const { currentRefinement, refine } = this.props;
    this.onChange = this.onChange.bind(this);
    this.debounceSearch = debounce(() => refine(this.state.query), 200);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchBlur = this.onSearchBlur.bind(this);
    this.onSearchEsc = this.onSearchEsc.bind(this);

    this.state = {
      query: currentRefinement,
    };
  }

  onChange(value) {
    this.setState(
      {
        query: value,
      },
      () => {
        this.debounceSearch();
      },
    );
  }

  onSearchClick(e) {
    if (this.props.onSearchClick) {
      this.props.onSearchClick(e);
    }
  }

  onSearchBlur() {
    if (this.props.onSearchBlur) {
      this.onChange('');
      this.props.onSearchBlur();
    }
  }

  onSearchEsc(e) {
    if (this.props.onSearchEsc) {
      if (e.keyCode === 27) {
        if (!this.state.query) {
          e.target.blur();
        }
        this.onChange('');
      }
    }
  }

  render() {
    return (
      <TextInput
        id="searchBox"
        fluid
        type="search"
        autoFocus={this.props.autoFocus}
        value={this.state.query}
        onChange={this.onChange}
        icon={ICONS.SEARCH}
        placeholder={this.props.intl.formatMessage(messages.searchPlaceholder)}
        label={this.props.intl.formatMessage(messages.searchLabel)}
        onBlur={this.onSearchBlur}
        onClick={this.onSearchClick}
        onKeyDown={this.onSearchEsc}
      />
    );
  }
}

SearchBox.propTypes = {
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
  intl: PropTypes.shape().isRequired,
  autoFocus: PropTypes.bool,
  onSearchClick: PropTypes.func,
  onSearchBlur: PropTypes.func,
  onSearchEsc: PropTypes.func,
};

SearchBox.defaultProps = {
  autoFocus: false,
  onSearchClick: undefined,
  onSearchBlur: undefined,
  onSearchEsc: undefined,
};

export default compose(injectIntl)(connectSearchBox(SearchBox));
