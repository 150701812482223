import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cx from 'classnames';
import { injectIntl } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
// eslint-disable-next-line css-modules/no-unused-class
import s from '../../RedeemOverview.scss';
import { ICONS, autoCompleteValues } from '../../../../constants';
import Clickable from '../../../Clickable';
import Icon from '../../../Icon';
import Label from '../../../Label';
import Grid from '../../../Grid';
import {
  redeemMessages as messages,
  basketMessages,
  formMessages,
} from '../../../../defineMessages';
import { addRedeem, removeRedeem } from '../../../../actions/basket';
import TextInput from '../../../TextInput/TextInput';
import {
  isVoucherCodePorOrSgk,
  removeExtraHyphens,
  trimWhiteSpace,
} from '../../../../helpers';

const RegisteredCodes = ({ intl, vouchers, dispatch, salesChannelGroup }) => {
  const [loading, setLoading] = useState(false);
  const [showInputs, setShowInputs] = useState(false);
  const [formValues, setFormValues] = useState({
    voucher: '',
    pin: '',
  });
  const [registeredCodes, setRegisteredCodes] = useState(true);

  const removeVoucher = async (id, boughtByB2bDepartment) => {
    await dispatch(removeRedeem({ id, boughtByB2bDepartment }));
  };

  const setField = (fieldName, value) => {
    return setFormValues(prev => ({
      ...prev,
      [fieldName]: trimWhiteSpace(value),
    }));
  };

  const setFormErrors = (fieldName, message) => {
    setFormValues(prev => ({
      ...prev,
      errors: {
        ...prev.errors,
        [fieldName]: message,
      },
    }));
  };

  const validateVoucherField = () => {
    const { voucher } = formValues;
    const errorMessage = intl.formatMessage({
      ...formMessages.required,
    });
    if (!voucher) {
      setFormErrors('voucher', errorMessage);
      return false;
    }

    setFormErrors('voucher', false);
    return true;
  };

  const validatePinField = () => {
    const { voucher, pin } = formValues;
    const errorMessage = intl.formatMessage({
      ...formMessages.required,
    });

    const needsPin = !isVoucherCodePorOrSgk(voucher);
    if (needsPin && !pin) {
      setFormErrors('pin', errorMessage);
      return false;
    }
    setFormErrors('pin', false);
    return true;
  };

  const resetFields = () => {
    setShowInputs(false);
    setFormValues({
      voucher: '',
      pin: '',
    });
  };

  const addNewVoucher = async event => {
    const { voucher: valueCode, pin: securityCode } = formValues;
    event.preventDefault();
    setLoading(true);

    const redeem = {
      valueCode: removeExtraHyphens(valueCode),
      securityCode,
    };

    // Action shows any errors. Just skip redirect if any error.
    // Returns salesChannelId when voucher starts with 'por-' and is for another sales channel
    const res = await dispatch(
      addRedeem({ voucher: redeem, salesChannelGroup }),
    );
    if (!res) {
      setLoading(false);
      return false;
    }

    resetFields();
    return true;
  };

  return (
    <div
      className={cx(
        s.registeredcodes,
        registeredCodes && s['registeredcodes--open'],
      )}
    >
      <Clickable
        className={cx(s.item, s.item__header)}
        onClick={() => setRegisteredCodes(!registeredCodes)}
      >
        <div className={s.fee}>
          <Label bold className={s.fee__label}>
            {intl.formatMessage({
              ...messages.redeemOverviewRegisteredCodes,
            })}
          </Label>
          <Label bold>
            <Icon size={13} name={ICONS.CHEVRON_DOWN} />
          </Label>
        </div>
      </Clickable>

      {vouchers &&
        vouchers.map(item => (
          <div className={s.item} key={item.id}>
            <div className={s.remove}>
              <Label bold className={s.remove__label}>
                {item.voucherMasked}
                <span className={s.remove__sublabel}>
                  {intl.formatMessage({
                    ...messages.redeemOverviewExpiry,
                  })}
                  : {item.expiresAt}
                </span>
              </Label>
              <Label>
                <Clickable
                  naked
                  className={s.remove__button}
                  onClick={() =>
                    removeVoucher(item.id, item.boughtByB2bDepartment)
                  }
                  label={intl.formatMessage({
                    ...messages.redeemOverviewRemove,
                  })}
                />
              </Label>
            </div>
          </div>
        ))}

      <Grid container justify="center" className={s.addcode}>
        {showInputs ? (
          <form onSubmit={addNewVoucher}>
            <div className={s['form-row']}>
              <TextInput
                name="voucher"
                label={intl.formatMessage({ ...messages.voucherCode })}
                hasError={formValues.errors?.voucher || false}
                showLabel
                required
                autoComplete={autoCompleteValues.autoCompleteOff}
                value={formValues.voucher}
                onBlur={validateVoucherField}
                onChange={value => setField('voucher', value)}
                disabled={loading}
              />
              <TextInput
                name="pin"
                label={intl.formatMessage({ ...messages.pinCode })}
                autoComplete={autoCompleteValues.autoCompleteOff}
                hasError={formValues.errors?.pin || false}
                showLabel
                required
                onBlur={validatePinField}
                value={formValues.pin}
                onChange={value => setField('pin', value)}
                disabled={loading}
              />
            </div>
            <div className={s['form-row']}>
              <Clickable
                id="cancelRedeem"
                fluid
                type="button"
                label={intl.formatMessage({ ...basketMessages.cancel })}
                onClick={() => resetFields()}
                disabled={loading}
                basic
              />
              <Clickable
                id="submitRedeem"
                fluid
                primary
                type="submit"
                disabled={!formValues.voucher || !formValues.pin}
                label={intl.formatMessage({ ...messages.register })}
                loading={loading}
              />
            </div>
          </form>
        ) : (
          <Grid item>
            <Clickable
              primary
              fluid
              onClick={() => {
                setLoading(false);
                setShowInputs(true);
              }}
            >
              {intl.formatMessage({
                ...messages.redeemOverviewAddCode,
              })}
            </Clickable>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

RegisteredCodes.propTypes = {
  intl: PropTypes.shape().isRequired,
  vouchers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  dispatch: PropTypes.func.isRequired,
  salesChannelGroup: PropTypes.string.isRequired,
};

const mapState = state => ({
  vouchers: state.basket.vouchers,
  salesChannelGroup:
    state.config.salesChannels[state.config.salesChannelId].group,
});

export default compose(
  injectIntl,
  withStyles(s),
  connect(mapState),
)(RegisteredCodes);
