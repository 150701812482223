import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import s from './Header.scss';
import Link from '../Link';
import {
  ICONS,
  BASKET_PATH,
  usps,
  superGiftCardMenu,
  localSuperGiftCardMenu,
  menuLinksTop,
  menuLinksBottom,
  REDEEM_PATH,
  SHOP_PATH,
  MAX_LINKS_IN_DROPDOWN,
  ACCOUNT_TYPE,
  OCCASIONS_PATH,
  GLOBAL_SITE_PATH,
} from '../../constants';
import Grid from '../Grid';
import Button from '../Clickable';
import Label from '../Label';
import List from '../List';
import Brand from '../Brand';
import MobileMenu from '../MobileMenu';
import Menu from '../Menu';
import Search from '../Search';
import UserMenu from '../UserMenu';
import Usps from './components/Usps';
import { toggleMobileMenu } from '../../actions/header';
import withResponsiveWrapper from '../../decorators/withResponsiveWrapper';
import withUser from '../../decorators/withUser';
import { headerMessages as messages } from '../../defineMessages';
import { triggerSeeAllGiftCardClickEvent } from '../../helpers/googleTagManager';
import BusinessButton from '../BusinessButton/BusinessButton';

// Brand might as well just take the logo as a prop (directly from state)
const RenderBrand = ({ logo }) => (
  <div className={s.item}>
    <Link className={s.link} to="/" id="logo">
      {logo ? <img src={logo} alt="Logo" className={s.portal} /> : <Brand />}
    </Link>
  </div>
);

RenderBrand.propTypes = {
  logo: PropTypes.string,
};
RenderBrand.defaultProps = {
  logo: undefined,
};

const reduceMenuItemsToShowInDropdown = (items, type, label) => {
  const leftOverItems = [...items];
  if (items.length < MAX_LINKS_IN_DROPDOWN) {
    return items;
  }

  // splice out the filtered item from leftOverItems
  // to then later on add them in the array if there are more room for it in filteredMenu
  // Note: There will only be more room if filteredMenu length is less then MAX_LINKS_IN_DROPDOWN

  const filteredMenu = items.filter((item, index) => {
    if (item.prioritisedMenuItem < MAX_LINKS_IN_DROPDOWN) {
      leftOverItems.splice(index, 1);
    }
    return item.prioritisedMenuItem < MAX_LINKS_IN_DROPDOWN;
  });

  // if there are room for more items add the leftOverItems to filteredMenu
  if (filteredMenu.length < MAX_LINKS_IN_DROPDOWN) {
    for (let i = 0; filteredMenu.length < MAX_LINKS_IN_DROPDOWN; i += 1) {
      filteredMenu.push(leftOverItems[i]);
    }
  }

  return [
    {
      id: `isSeeAll-${type}`,
      to: `/${type}`,
      label,
      isSeeAll: true,
    },
    ...filteredMenu,
  ];
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uspsHidden: false,
    };
    this.gtmSeeAllGiftCardEvent = this.gtmSeeAllGiftCardEvent.bind(this);
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll, false);
  }

  onScroll = () => {
    if (!this.props.isDesktop) {
      const pageOffset =
        window.pageYOffset || document.documentElement.scrollTop;
      if (pageOffset <= 40) {
        this.setState({ uspsHidden: false });
      } else {
        this.setState({ uspsHidden: true });
      }
    }
  };

  gtmSeeAllGiftCardEvent = () => {
    const { currency, salesChannelId, mode } = this.props;
    triggerSeeAllGiftCardClickEvent({
      eventData: {
        currencyCode: currency,
        actionField: {
          list: 'header',
          salesChannelId,
          mode,
        },
      },
    });
  };

  render() {
    const {
      isMobile,
      menu,
      itemsInBasket,
      disableBasket,
      intl,
      user,
      mode,
      logo,
      presentations,
      dispatch,
      showServiceMessage,
      showFullHeader,
      salesChannelId,
      currency,
      showGlobalLink,
    } = this.props;

    const { uspsHidden } = this.state;

    const superGiftCardMenuItems = superGiftCardMenu
      .filter(({ hideForModes }) => !hideForModes.includes(mode))
      .map(({ label, to, ...rest }) => ({
        label: intl.formatMessage({ ...messages[label] }),
        to: to || presentations.countryLinkSuperGiftcard,
        ...rest,
      }));

    const localSuperGiftCardMenuItems = localSuperGiftCardMenu
      .filter(({ hideForModes }) => !hideForModes.includes(mode))
      .map(({ label, to, ...rest }) => ({
        label: intl.formatMessage({ ...messages[label] }),
        to: to || presentations.countryLinkSuperGiftcard,
        ...rest,
      }));
    const visibleGiftcardMenuItems = {};

    if (
      mode !== ACCOUNT_TYPE.REDEEM &&
      presentations.countryLinkSuperGiftcard
    ) {
      visibleGiftcardMenuItems.localGlobalGiftCard = localSuperGiftCardMenuItems;
    }
    if (mode === ACCOUNT_TYPE.B2B) {
      visibleGiftcardMenuItems.globalGiftCard = superGiftCardMenuItems;
    }

    const occasions = reduceMenuItemsToShowInDropdown(
      menu.occasions,
      'occasion',
      intl.formatMessage({ ...messages.seeAllOccasions }),
    );
    const categories = reduceMenuItemsToShowInDropdown(
      menu.categories,
      'category',
      intl.formatMessage({ ...messages.seeAllCategories }),
    );

    const brands = reduceMenuItemsToShowInDropdown(
      menu.brands,
      'brand',
      intl.formatMessage({ ...messages.seeAllBrands }),
    );

    return (
      <div className={cx(s.root, showServiceMessage && s.root__serviceMessage)}>
        <header
          className={
            mode !== ACCOUNT_TYPE.REDEEM || !isMobile
              ? s.header
              : s.header__folded
          }
        >
          {/* nav for seo enhancement, will never be visible for the user */}
          <nav className={s.seoNav}>
            <ul>
              {menuLinksTop.map(item => (
                <li key={item.label}>
                  <Link to={item.to}>
                    <FormattedMessage {...messages[item.label]} />
                  </Link>
                </li>
              ))}
              {visibleGiftcardMenuItems &&
                Object.keys(visibleGiftcardMenuItems).map(key => (
                  <li key={key}>
                    <FormattedMessage {...messages[key]} />
                    <ul>
                      {visibleGiftcardMenuItems[key].map(item => (
                        <li key={item.id}>
                          <Link to={item.to}>{item.label}</Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              {Object.keys(menu).map(key => (
                <li key={key}>
                  <FormattedMessage {...messages[key]} />
                  <ul>
                    {menu[key].map(item => (
                      <li key={item.id}>
                        <Link to={item.to}>{item.label}</Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
              {menuLinksBottom.map(item => (
                <li key={item.label}>
                  <Link to={item.to}>
                    <FormattedMessage {...messages[item.label]} />
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          <section className={s.header__top}>
            <Grid container className={s.container}>
              <Grid item xs={24}>
                <Grid
                  container
                  wrap="nowrap"
                  spacing={16}
                  className={s.container__inner}
                >
                  {isMobile && (
                    <Grid item xs="auto" className={s.mobileMenu}>
                      <div className={s.item}>
                        <MobileMenu
                          items={{
                            ...visibleGiftcardMenuItems,
                            ...menu,
                          }}
                          user={user}
                          handleClick={() => dispatch(toggleMobileMenu('main'))}
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item xs="auto">
                    <List
                      container
                      spacing={12}
                      alignItems="center"
                      role="navigation"
                      className={s.item}
                    >
                      <List item component="li">
                        <RenderBrand logo={logo} />
                      </List>
                      {mode !== ACCOUNT_TYPE.B2B &&
                        !isMobile &&
                        showFullHeader && (
                          <Fragment>
                            <List item component="li" className={s.item}>
                              <Button
                                id="redeemButton"
                                primary
                                link={<Link to={REDEEM_PATH} />}
                              >
                                <FormattedMessage
                                  {...messages.redeemGiftCard}
                                />
                              </Button>
                            </List>
                          </Fragment>
                        )}
                    </List>
                  </Grid>
                  {!isMobile && showFullHeader && (
                    <Grid item sm>
                      <div className={s.item}>
                        <div style={{ width: '100%' }}>
                          <Search />
                        </div>
                      </div>
                    </Grid>
                  )}

                  <Grid item xs="auto" className={s.iconMenuWrapper}>
                    <List
                      container
                      spacing={12}
                      wrap="nowrap"
                      alignItems="center"
                      justify="flex-end"
                      className={s.item}
                    >
                      {user && (
                        <List item component="li">
                          <UserMenu user={user} />
                        </List>
                      )}
                      {!user && mode !== ACCOUNT_TYPE.REDEEM && (
                        <List item component="li" className="hidden-xs">
                          <BusinessButton />
                        </List>
                      )}
                      {showGlobalLink && (
                        <List item component="li" className={s.basket}>
                          <Button
                            tertiary
                            link={<Link unprefixed to={GLOBAL_SITE_PATH} />}
                          >
                            {intl.formatMessage({
                              ...messages.global,
                            })}
                          </Button>
                        </List>
                      )}
                      {isMobile && showFullHeader && (
                        <List item component="li" className={s.mobileSearch}>
                          <Search />
                        </List>
                      )}

                      <List item component="li" className={s.basket}>
                        <Button
                          id="basketButton"
                          basic
                          disabled={disableBasket}
                          icon={{ name: ICONS.CART }}
                          link={<Link to={BASKET_PATH} />}
                        />
                        {itemsInBasket > 0 && (
                          <div className={s.counter}>
                            {itemsInBasket > 999 ? '∞' : itemsInBasket || 0}
                          </div>
                        )}
                      </List>
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </section>
          {(!isMobile || mode !== ACCOUNT_TYPE.REDEEM) && showFullHeader && (
            <section
              className={cx(
                s.header__bottom,
                uspsHidden && s.header__hiddenBottomSection,
              )}
            >
              <Grid container className={s.container}>
                <Grid
                  item={mode !== ACCOUNT_TYPE.REDEEM || !isMobile}
                  xs={mode !== ACCOUNT_TYPE.REDEEM || !isMobile ? 24 : false}
                >
                  <Grid
                    container
                    className={s.container__inner}
                    spacing={16}
                    wrap="nowrap"
                  >
                    {!isMobile && (
                      <Grid item md={16} className={s.item}>
                        <List
                          component="ul"
                          container
                          wrap="nowrap"
                          className={s.menu}
                        >
                          {Object.keys(visibleGiftcardMenuItems).map(key => (
                            <List
                              item
                              component="li"
                              key={`${key}_${new Date().getTime()}`}
                            >
                              <Menu
                                className={cx(
                                  s.menu__item,
                                  s['menu__item--first'],
                                )}
                                items={visibleGiftcardMenuItems[key]}
                                itemsClassName={s.menu__child}
                                salesChannelId={salesChannelId}
                                mode={mode}
                                currency={currency}
                              >
                                <Label bold>
                                  {intl.formatMessage({
                                    ...messages[key],
                                  })}
                                </Label>
                              </Menu>
                            </List>
                          ))}
                          <List item component="li">
                            <Link
                              to={SHOP_PATH}
                              className={s.menu__item}
                              onClick={this.gtmSeeAllGiftCardEvent}
                            >
                              <Label bold>
                                {intl.formatMessage({
                                  ...messages.seeAllGiftcards,
                                })}
                              </Label>
                            </Link>
                          </List>
                          <List item component="li">
                            <Menu
                              className={s.menu__item}
                              items={categories}
                              itemsClassName={s.menu__child}
                              salesChannelId={salesChannelId}
                              mode={mode}
                              currency={currency}
                            >
                              <Label bold>
                                {intl.formatMessage({
                                  ...messages.categories,
                                })}
                              </Label>
                            </Menu>
                          </List>
                          {occasions && occasions.length > 0 && (
                            <List item component="li">
                              <Menu
                                className={s.menu__item}
                                items={occasions}
                                itemsClassName={s.menu__child}
                                salesChannelId={salesChannelId}
                                mode={mode}
                                currency={currency}
                              >
                                <Link
                                  to={OCCASIONS_PATH}
                                  className={s.menu__item}
                                >
                                  <Label bold>
                                    {intl.formatMessage({
                                      ...messages.occasions,
                                    })}
                                  </Label>
                                </Link>
                              </Menu>
                            </List>
                          )}
                          <List item component="li">
                            <Menu
                              className={s.menu__item}
                              items={brands}
                              itemsClassName={s.menu__child}
                              salesChannelId={salesChannelId}
                              mode={mode}
                              currency={currency}
                            >
                              <Label bold>
                                {intl.formatMessage({ ...messages.brands })}
                              </Label>
                            </Menu>
                          </List>
                        </List>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs="auto"
                      md={8}
                      className={cx(s.item, s.item__usps)}
                    >
                      {mode !== ACCOUNT_TYPE.REDEEM && (
                        <Usps
                          items={usps}
                          className={isMobile ? s.usps : ''}
                          center={isMobile}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </section>
          )}
        </header>
      </div>
    );
  }
}

Header.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  menu: PropTypes.shape().isRequired,
  itemsInBasket: PropTypes.number,
  disableBasket: PropTypes.bool.isRequired,
  user: PropTypes.shape(),
  intl: PropTypes.shape().isRequired,
  logo: PropTypes.string,
  presentations: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  showServiceMessage: PropTypes.bool,
  showFullHeader: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  salesChannelId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  showGlobalLink: PropTypes.bool,
};

Header.defaultProps = {
  user: undefined,
  itemsInBasket: 0,
  logo: undefined,
  showServiceMessage: false,
  showFullHeader: true,
  showGlobalLink: false,
};

const mapState = state => ({
  itemsInBasket: state.basket.itemsCount,
  disableBasket: state.basket.loading || false,
  menu: state.config.menu,
  mode: state.user.mode,
  salesChannelId: state.config.salesChannelId,
  currency: state.intl.currency,
  presentations: state.config.presentations[state.intl.countryCode],
});

export default compose(
  injectIntl,
  withResponsiveWrapper(),
  withUser(),
  withStyles(s),
  connect(mapState),
)(Header);
