// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3kh6w{display:flex;justify-content:flex-end}._3QOSU{display:flex;justify-content:center}\n", ""]);
// Exports
exports.locals = {
	"right": "_3kh6w",
	"center": "_3QOSU"
};
module.exports = exports;
