import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';
import { ICONS } from '../../constants';

/**
 * This is just a wrapper around {@link Icon} to serve as an anchor element
 * for a 'title' attribute, since this cannot be applied to svg elements.
 */
function TooltipIcon({ tooltip, iconClassName }) {
  return (
    <span title={tooltip}>
      <Icon name={ICONS.INFO} className={iconClassName} />
    </span>
  );
}

TooltipIcon.propTypes = {
  tooltip: PropTypes.string.isRequired,
  iconClassName: PropTypes.string,
};

TooltipIcon.defaultProps = {
  iconClassName: undefined,
};

export default TooltipIcon;
