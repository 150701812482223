import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './Menu.scss';
import Grid from '../Grid';
import Link from '../Link';
import List from '../List';
import Clickable from '../Clickable';
import { sortBy } from '../../helpers';
import { getProductIdFromUrl } from '../../helpers/url';
import { triggerSuperGiftCardClickEvent } from '../../helpers/googleTagManager';

const itemsToColumns = (items, columnLimit) => {
  const result = [];
  const columns = Math.ceil(items.length / columnLimit);
  for (let i = 0; i < columns; i += 1) {
    result.push(items.slice(i * columnLimit, i * columnLimit + columnLimit));
  }
  return result;
};

class Menu extends Component {
  state = {
    isOpen: false,
  };

  handleOnMouseEnter = () => this.setState({ isOpen: true });

  handleOnMouseOut = () => this.setState({ isOpen: false });

  gtmSuperGiftCardClickEvent = (localId, to, isSuperCardBuy, label) => {
    const { currency: currencyCode, mode, salesChannelId } = this.props;
    this.handleOnMouseOut();
    if (isSuperCardBuy) {
      const productId = getProductIdFromUrl(to);
      triggerSuperGiftCardClickEvent({
        eventData: {
          currencyCode,
          click: {
            actionField: {
              list: 'header',
              salesChannelId,
              mode,
            },
            products: [
              {
                id: productId,
                name: label,
              },
            ],
          },
        },
      });
    }
  };

  render() {
    const {
      children,
      items,
      className,
      itemsClassName,
      position,
      ...props
    } = this.props;
    const { isOpen } = this.state;
    const sortedItems = items.sort(sortBy('label'));
    const indexOfSeeAllItem = sortedItems.findIndex(item => item?.isSeeAll);

    if (indexOfSeeAllItem !== -1) {
      const seeAllItem = sortedItems.splice(indexOfSeeAllItem, 1)[0];
      sortedItems.push(seeAllItem);
    }

    const columns = itemsToColumns(sortedItems, 18);
    return (
      <Clickable
        onMouseOver={this.handleOnMouseEnter}
        onFocus={this.handleOnMouseEnter}
        onMouseOut={this.handleOnMouseOut}
        onBlur={this.handleOnMouseOut}
        className={cx(s.root, className && className)}
        {...props}
      >
        {children}
        {items && (
          <div
            className={cx(
              s.submenu,
              isOpen && s['submenu--open'],
              position && s[`submenu--pos-${position}`],
            )}
          >
            <Grid container spacing={32} wrap="nowrap">
              {columns &&
                columns.map(column => (
                  <Grid item key={column[0].id}>
                    <List
                      container
                      direction="column"
                      wrap="nowrap"
                      spacing={8}
                    >
                      {column &&
                        column.map(
                          ({ id, label, to, isSeeAll, isSuperCardBuy }) =>
                            to && (
                              <List key={id} item component="li">
                                <Link
                                  to={to}
                                  className={cx(
                                    s.item,
                                    itemsClassName && itemsClassName,
                                    isSeeAll && s.seeAllItem,
                                  )}
                                  onClick={() => {
                                    this.gtmSuperGiftCardClickEvent(
                                      id,
                                      to,
                                      isSuperCardBuy,
                                      label,
                                    );
                                  }}
                                >
                                  {label}
                                </Link>
                              </List>
                            ),
                        )}
                    </List>
                  </Grid>
                ))}
            </Grid>
          </div>
        )}
      </Clickable>
    );
  }
}

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  itemsClassName: PropTypes.string,
  position: PropTypes.string,
  mode: PropTypes.string.isRequired,
  salesChannelId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

Menu.defaultProps = {
  itemsClassName: undefined,
  position: undefined,
};

export default withStyles(s)(Menu);
