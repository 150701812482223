import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './MobileMenu.scss';
import Link from '../Link';
import List from '../List';
import {
  ICONS,
  menuLinksTop,
  menuLinksBottom,
  LOGOUT_PATH,
  SHOP_PATH,
} from '../../constants';
import Modal from '../Modal';
import Label from '../Label';
import Button from '../Clickable';
import {
  headerMessages as messages,
  userMessages,
  footerMessages,
} from '../../defineMessages';
import Accordion from '../Accordion';
import Grid from '../Grid/Grid';
import { sortBy } from '../../helpers';
import { getProductIdFromUrl } from '../../helpers/url';
import {
  triggerSuperGiftCardClickEvent,
  triggerSeeAllGiftCardClickEvent,
} from '../../helpers/googleTagManager';
import BusinessButton from '../BusinessButton/BusinessButton';

const MobileMenu = ({
  isVisible,
  handleClick,
  items,
  user,
  currency,
  salesChannelId,
  mode,
}) => {
  const gtmSuperGiftCardClickEvent = ({ to, isSuperCardBuy, label }) => {
    if (isSuperCardBuy) {
      const productId = getProductIdFromUrl(to);
      triggerSuperGiftCardClickEvent({
        eventData: {
          currencyCode: currency,
          click: {
            actionField: {
              list: 'mobileMenu',
              salesChannelId,
              mode,
            },
            products: [
              {
                id: productId,
                name: label,
              },
            ],
          },
        },
      });
    }
  };
  const gtmSeeAllGiftCardEvent = () => {
    triggerSeeAllGiftCardClickEvent({
      eventData: {
        currencyCode: currency,
        actionField: {
          list: 'mobileMenu',
          salesChannelId,
          mode,
        },
      },
    });
  };
  const gtmLinkClick = ({ to, isSuperCardBuy, label }) => {
    if (isSuperCardBuy) {
      gtmSuperGiftCardClickEvent({ to, isSuperCardBuy, label });
    }
    if (to === SHOP_PATH) {
      gtmSeeAllGiftCardEvent();
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      handleClick={handleClick}
      title="Menu"
      triggerProps={{
        icon: { name: ICONS.BURGER, size: 24 },
      }}
      maxWidth
      fullScreen
      animation="slideLeft"
    >
      {user ? (
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={s.user}
          wrap="nowrap"
        >
          <Grid item>
            <Label tag="p">
              <FormattedMessage {...userMessages.mobileUser} />
            </Label>
            <Label bold>
              {user.name}, {user.company}
            </Label>
          </Grid>
          <Grid item>
            <Link unprefixed to={LOGOUT_PATH} className={s.logout}>
              <FormattedMessage {...userMessages.mobileLogout} />
            </Link>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item className={s.button}>
            <BusinessButton fluid />
          </Grid>
        </Grid>
      )}
      <List container direction="column">
        {menuLinksTop.map(item => (
          <List key={item.label} item component="li">
            <Link
              to={item.to}
              className={s.item}
              onClick={() => {
                gtmLinkClick(item);
              }}
            >
              <Label bold>
                <FormattedMessage {...messages[item.label]} />
              </Label>
            </Link>
          </List>
        ))}
      </List>

      <Accordion
        className={s.accordion}
        items={Object.keys(items).map(menuName => {
          return {
            key: menuName,
            title: (
              <Label bold>
                <FormattedMessage {...messages[menuName]} />
              </Label>
            ),
            body: (
              <List container direction="column" spacing={16}>
                {items[menuName]
                  .sort(sortBy('label'))
                  .map(({ id, label, to, isSuperCardBuy }) => (
                    <List item component="li" key={id}>
                      <Link
                        to={to}
                        onClick={() => {
                          gtmLinkClick({ id, label, to, isSuperCardBuy });
                        }}
                      >
                        {label}
                      </Link>
                    </List>
                  ))}
              </List>
            ),
          };
        })}
      />
      <List container direction="column">
        {menuLinksBottom.map(item => (
          <List key={item.label} item component="li">
            <Label bold>
              <Link
                to={item.to}
                className={s.item}
                onClick={() => {
                  gtmLinkClick(item);
                }}
              >
                <FormattedMessage {...messages[item.label]} />
              </Link>
            </Label>
          </List>
        ))}
        <List item component="li" className={s.buttonListItem}>
          <Button
            fluid
            onClick={evt => {
              evt.preventDefault();
              if (window.Cookiebot) {
                window.Cookiebot.renew();
              }
            }}
            label={<FormattedMessage {...footerMessages.manageCookieConsent} />}
          />
        </List>
      </List>
    </Modal>
  );
};

MobileMenu.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  items: PropTypes.shape().isRequired,
  user: PropTypes.shape(),
  mode: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  salesChannelId: PropTypes.string.isRequired,
};

MobileMenu.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  isVisible: state.header.main,
  mode: state.user.mode,
  salesChannelId: state.config.salesChannelId,
  currency: state.intl.currency,
});

export default compose(withStyles(s), connect(mapStateToProps))(MobileMenu);
