import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import { ICONS } from '../../../../constants';
import Label from '../../../Label';
import { headerMessages as messages } from '../../../../defineMessages';
import Slider from '../../../Slider';
import s from './Usps.scss';

const Usps = ({ items, center, ...props }) => (
  <Slider
    naturalSlideHeight={100}
    visibleSlides={1}
    arrows={false}
    responsive={false}
    isPlaying
    {...props}
  >
    {items.map(({ key, label, icon }) => (
      <div key={key} className={center ? s.center : s.right}>
        <Label bold icon={{ name: ICONS[icon], size: 24 }}>
          <FormattedMessage {...messages[label]} />
        </Label>
      </div>
    ))}
  </Slider>
);

Usps.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  center: PropTypes.bool,
};

Usps.defaultProps = {
  center: false,
};

export default withStyles(s)(Usps);
