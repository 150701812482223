// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1A8qS{position:relative;background:transparent;border:none;appearance:none;text-align:left}._21o83{display:none;position:absolute;z-index:1;top:100%;left:0;background-color:rgba(255,255,255,0.95);padding:16px 24px}.mUZ6o{display:block}.GacNN{color:inherit;padding:4px;white-space:nowrap}.QJYJI{color:var(--gogiftblue)}\n", ""]);
// Exports
exports.locals = {
	"root": "_1A8qS",
	"submenu": "_21o83",
	"submenu--open": "mUZ6o",
	"item": "GacNN",
	"seeAllItem": "QJYJI"
};
module.exports = exports;
