import React from 'react';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { connectHits } from 'react-instantsearch-dom';
import withStyles from 'isomorphic-style-loader/withStyles';
import List from '../List/List';
import Label from '../Label/Label';
import s from './Hits.scss';
import SearchListItem from '../SearchListItem/SearchListItem';
import { SHOP_PATH, OCCASIONS_PATH } from '../../constants';
import { filterSearchResults } from '../../helpers';

class Hits extends React.Component {
  constructor(props) {
    super(props);
    this.onItemClick = this.onItemClick.bind(this);
  }

  onItemClick(ev) {
    if (this.props.onItemClick) {
      this.props.onItemClick(ev);
    }
  }

  getSlug = ({ indexName, hit, title }) => {
    if (indexName === 'product') {
      return `${SHOP_PATH}/${slugify(title || '-', { lower: true })}/${hit.id}`;
    }

    const categoryGroup =
      indexName === 'category' && hit.categoryGroup.toLowerCase();
    if (indexName === 'category' && categoryGroup === 'occasion') {
      return `${OCCASIONS_PATH}/${slugify(title || '-', { lower: true })}/${
        hit.id
      }`;
    }
    if (indexName === 'category' && categoryGroup !== 'regular') {
      return `${SHOP_PATH}?${categoryGroup}=${hit.id}`;
    }
    return `${SHOP_PATH}?${indexName}=${hit.id}`;
  };

  render() {
    const {
      index,
      locale,
      filters: { recipient, category, occasion, brand },
    } = this.props;
    const categoryIdsInState = [
      ...Object.keys(recipient),
      ...Object.keys(category),
      ...Object.keys(occasion),
    ];
    const brandIdsInState = [...Object.keys(brand)];

    let { hits } = this.props;
    if (index.indexName === 'brand') {
      hits = filterSearchResults(hits, brandIdsInState).slice(0, 5);
    }

    if (index.indexName === 'category') {
      hits = filterSearchResults(hits, categoryIdsInState).slice(0, 5);
    }

    return (
      <>
        <div className={s.indexLabel}>
          <Label bold uppercase>
            {index.label}
          </Label>
        </div>
        <div className={s.resultsContainer}>
          <List
            container
            direction="column"
            className={
              index.indexName === 'product' ? s.productResults : s.results
            }
          >
            {hits.map(hit => {
              let title;
              if (index.indexName === 'brand') {
                title = hit.name;
              }
              if (index.indexName === 'category') {
                title = hit.name[locale] || hit.name.en;
              }
              if (index.indexName === 'product') {
                title = hit.title[locale] || hit.title.en;
              }

              return (
                <SearchListItem
                  title={title}
                  key={`hit-${index.indexName}-${hit.objectID}`}
                  description={
                    hit.shortDescription
                      ? hit.shortDescription[locale] || hit.shortDescription.en
                      : ''
                  }
                  image={hit.logo}
                  price={hit.minPriceValue}
                  currency={hit.defaultPriceCurrency}
                  slug={this.getSlug({
                    indexName: index.indexName,
                    hit,
                    title,
                  })}
                  type={index.indexName}
                  onItemClick={this.onItemClick}
                  id={hit.id}
                />
              );
            })}
          </List>
        </div>
      </>
    );
  }
}

Hits.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  index: PropTypes.shape().isRequired,
  onItemClick: PropTypes.func,
  locale: PropTypes.string.isRequired,
  filters: PropTypes.shape().isRequired,
};

Hits.defaultProps = {
  onItemClick: undefined,
};

const mapState = state => ({
  locale: state.intl.locale,
  filters: state.shop.filters,
});

export default compose(withStyles(s), connect(mapState))(connectHits(Hits));
